import React from "react";
import clsx from "clsx";

type TypographyProps = {
  variant: string;
  className?: string;
  children: React.ReactNode;
  isHomePage?: boolean;
};

type VariantStyle = {
  tag: React.ElementType;
  classes: string;
};

const variantToStyleMapping: Record<string, VariantStyle> = {
  "hero-title": {
    tag: "h1",
    classes:
      "text-2.5 cmd:text-3.5625 text-white font-montserrat font-bold leading-2.925 md:leading-4.575",
  },
  "section-title": {
    tag: "h2",
    classes:
      "text-2.062 cmd:text-2.5 text-colorTertiary font-bold font-montserrat tracking-widest uppercase",
  },
  "box-title": {
    tag: "h3",
    classes:
      "text-1.438 customMedium:text-2.062 text-colorTertiary font-montserrat font-bold leading-1.875 tracking-0.234 capitalize",
  },
  "hero-subtitle": {
    tag: "h4",
    classes: "text-1.187 font-light font-roboto text-colorTertiary leading-7",
  },
  "subtitle-light": {
    tag: "h4",
    classes: "text-1.438 font-light text-colorTertiary font-roboto leading-7",
  },
  "subtitle-bold": {
    tag: "h4",
    classes: "text-xl font-bold text-colorTertiary font-montserrat leading-7",
  },
  "paragraph-light": {
    tag: "p",
    classes: "text-base font-light text-colorTertiary font-roboto leading-1.5",
  },
  "paragraph-bold": {
    tag: "p",
    classes: "text-base font-extrabold text-colorTertiary font-montserrat leading-6",
  },
  "breadcrumbs-normal": {
    tag: "p",
    classes: "text-0.6875 font-normal text-colorTertiary font-montserrat leading-0.8125",
  },
  "breadcrumbs-bold": {
    tag: "p",
    classes: "text-0.6875 text-colorTertiary font-bold font-montserrat leading-5",
  },
  "button-label": {
    tag: "p",
    classes: "text-0.8125 text-colorTertiary font-montserrat leading-sm tracking-0.081 uppercase",
  },
  "span-bold": {
    tag: "span",
    classes: "font-semibold text-colorTertiary font-montserrat leading-6",
  },
};

const Typography: React.FC<TypographyProps> = ({ variant, className, children, isHomePage }) => {
  const { tag, classes } = variantToStyleMapping[variant] || variantToStyleMapping.paragraph;
  const Tag = tag;
  const combinedClasses = clsx(classes, className);

  return isHomePage ? (
    <Tag className={combinedClasses} dangerouslySetInnerHTML={{ __html: children }} />
  ) : (
    <Tag className={combinedClasses}>{children}</Tag>
  );
};

export default Typography;
